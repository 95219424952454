<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../../../assets/home/banner.png" alt="" />
      <div class="banner-message">
        <div class="d-l-f">{{ t("home.banner_message") }}</div>
        <div class="d-s-f">{{ t("home.banner_sub_message") }}</div>
        <a id="homeBtn" class="banner-btn" href="http://baidu.com" target="_blank">{{
          t("home.banner_btn")
        }}</a>
      </div>
    </div>
    <div class="info-box">
      <h1 class="info-title">{{ t("home.solution_title") }}</h1>
      <div class="info-card">
        <div class="card">
          <img class="card-icon" src="../../../../assets/home/cdp-large.png" alt="" />
          <div class="card-title">{{ t("home.cdp") }}</div>
          <div class="card-text">{{ t("home.cdp_description") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../../../assets/home/cds-large.png" alt="" />
          <div class="card-title">{{ t("home.cds") }}</div>
          <div class="card-text">{{ t("home.cds_description") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../../../assets/home/ccm-large.png" alt="" />
          <div class="card-title">{{ t("home.ccm") }}</div>
          <div class="card-text">{{ t("home.ccm_description") }}</div>
        </div>
        <div class="card">
          <img
            class="card-icon"
            src="../../../../assets/home/iqplatform-large.png"
            alt=""
          />
          <div class="card-title">{{ t("home.iq") }}</div>
          <div class="card-text">{{ t("home.iq_description") }}</div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <h1 class="info-title">{{ t("home.data_pipeline") }}</h1>
      <div class="info-card info-card-img">
        <img class="img" src="../../../../assets/home/pipeline.png" alt="" />
      </div>
    </div>
    <div class="info-box">
      <h1 class="info-title">{{ t("home.module") }}</h1>
      <div class="info-card">
        <div class="card-img">
          <div class="img-box">
            <img src="../../../../assets/home/channel-manager.png" alt="" />
          </div>
          <div class="name">{{ t("home.module_channel") }}</div>
          <div class="d-item">{{t("home.module_channel_text1")}}</div>
          <div class="d-item">{{t("home.module_channel_text2")}}</div>
        </div>
        <div class="card-img">
          <div class="img-box">
            <img src="../../../../assets/home/campaign-manager.png" alt="" />
          </div>
          <div class="name">{{ t("home.module_campaign") }}</div>
          <div class="d-item">{{t("home.module_campaign_text1")}}</div>
          <div class="d-item">{{t("home.module_campaign_text2")}}</div>
          <div class="d-item">{{t("home.module_campaign_text3")}}</div>
          <div class="d-item">{{t("home.module_campaign_text4")}}</div>
        </div>
        <div class="card-img">
          <div class="img-box">
            <img src="../../../../assets/home/audience-manager.png" alt="" />
          </div>
          <div class="name">{{ t("home.module_audience") }}</div>
          <div class="d-item">{{t("home.module_audience_text1")}}</div>
          <div class="d-item">{{t("home.module_audience_text2")}}</div>
          <div class="d-item">{{t("home.module_audience_text3")}}</div>
          <div class="d-item">{{t("home.module_audience_text4")}}</div>
          <div class="d-item">{{t("home.module_audience_text5")}}</div>
          <div class="d-item">{{t("home.module_audience_text6")}}</div>
        </div>
        <div class="card-img">
          <div class="img-box">
            <img src="../../../../assets/home/data-master.png" alt="" />
          </div>
          <div class="name">{{ t("home.module_data") }}</div>
          <div class="d-item">{{t("home.module_data_text1")}}</div>
          <div class="d-item">{{t("home.module_data_text2")}}</div>
          <div class="d-item">{{t("home.module_data_text3")}}</div>
          <div class="d-item">{{t("home.module_data_text4")}}</div>
        </div>
      </div>
    </div>
    <section class="s-lunbo">
      <div class="d-lunbo">
        <swiper
          :slidesPerView="1"
          :spaceBetween="0"
          :loop="true"
          :navigation="true"
          :modules="modules"
          class="mySwiper d-box"
        >
          <swiper-slide v-for="(item, index) in arr" :key="index">
            <div class="d-item">
              <div class="d-img">
                <img :src="require('../../../../assets/home/' + item.banner)" alt="">
              </div>
              <div class="d-content">
                <img :src="require('../../../../assets/home/' + item.icon)" alt="">
                <div>
                  {{t(item.dec)}}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <div class="info-box">
      <h1 class="info-title">{{ t("home.customer") }}</h1>
      <div class="info-card info-card-img">
        <img class="img" src="../../../../assets/home/customer.png" alt="" />
      </div>
    </div>
    <HelpForm/>
  </div>
</template>
<script>
// import { ref } from "vue";
import { useI18n } from "vue-i18n";
import HelpForm from 'Components/HelpForm/index.vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { /* Pagination, */ Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
export default {
  components: {
    HelpForm,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      arr: [
        {banner: 'ak-banner.jpg', icon: 'ak-logo.jpg', dec: 'home.ak_dec'},
        {banner: 'mk-banner.jpg', icon: 'mk-logo.jpg', dec: 'home.mk_dec'},
        {banner: 'ch-banner.jpg', icon: 'ch-logo.jpg', dec: 'home.ch_dec'},
        {banner: 'sb-banner.jpg', icon: 'sb-logo.jpg', dec: 'home.sb_dec'}
      ],
      currentIndex: 0
    }
  },
  setup() {
    const { t } = useI18n();
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      t,
      onSwiper,
      onSlideChange,
      modules: [/* Pagination, */ Navigation],
    };
  },
  methods: {
    changePage(num) {
      this.currentIndex = this.currentIndex + num < 0 ? 3 : this.currentIndex + num > 3 ? 0 : this.currentIndex + num
    }
  },
};
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    .img {
      // width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-message {
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      width: 500px;
      margin: 0 auto;
      text-align: left;
      font-size: 40px;
      color: #ffffff;
      line-height: 2em;
      .d-l-f {
        font-size: 40px;
      }
      .d-s-f {
        font-size: 24px;
      }
      .banner-btn {
        display: block;
        width: 190px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        background: #ff9333;
        border-radius: 24px;
        margin-top: 34px;
      }
    }
  }
  .info-box {
    max-width: 1140px;
    margin: 100px auto;
    text-align: center;
    .info-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .info-card {
      display: flex;
      &.info-card-img {
        display: block;
      }
      .card {
        flex: 1;
        background: #f4f4f4;
        border-radius: 15px;
        padding: 20px 20px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        .card-icon {
          width: 60px;
          margin-bottom: 16px;
        }
        .card-title {
          color: #102b47;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 16px;
          line-height: 20px;
        }
        .card-text {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 24px;
        }
      }
      .img {
        width: 100%;
      }
      .card-img {
        padding: 30px 20px;
        margin-right: 40px;
        box-shadow: 0px 1px 10px 0px rgba(204, 204, 204, 0.6);
        &:last-child {
          margin-right: 0;
        }
        .img-box {
          height: 200px;
          img {
            width: 80%;
          }
        }
        .name {
          font-size: 20px;
          color: #333333;
          margin-bottom: 20px;
        }
        .d-item {
          padding: 0 15px;
          text-align: left;
          font-size: 12px;
          line-height: 30px;
          color: #333333;
          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #ef813b;
            margin-right: 15px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .s-lunbo {
    position: relative;
    width: 1140px;
    height: 400px;
    margin: 0 auto;
    .d-lunbo {
      position: relative;
      width: 100%;
      height: 100%;
      :deep(.swiper) {
        position: static;
      }
      .d-box {
        // position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0px 1px 10px 0px rgba(204, 204, 204, 0.6);
        :deep(.swiper-button-prev) {
          left: -25px;
          color: #cccccc;
          width: 50px;
          height: 50px;
          border: 2px solid #cccccc;
          border-radius: 50%;
          background: #ffffff;
          // background: url(../../assets/home/arrow-g.jpg);
          &::after {
            font-size: 24px;
          }
          &:hover {
            color: #ff9333;
            border-color: #ff9333;
          }
        }
        :deep(.swiper-button-next) {
          right: -25px;
          color: #cccccc;
          width: 50px;
          height: 50px;
          border: 2px solid #cccccc;
          border-radius: 50%;
          background: #ffffff;
          &::after {
            font-size: 24px;
          }
          &:hover {
            color: #ff9333;
            border-color: #ff9333;
          }
        }
        .d-item {
          position: absolute;
          top: 0;
          left: 0;
          width: 1140px;
          height: 400px;
          transition: width 0.5s ease-in-out;
          .d-img {
            height: 400px;
            float: left;
            img {
              height: 100%;
            }
          }
          .d-content {
            float: left;
            width: e("calc(100% - 470px)");
            font-size: 16px;
            line-height: 30px;
            color: #666666;
            padding: 50px 30px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>
